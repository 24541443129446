import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { animated, useSpring } from "react-spring"

import InternalLink, { StyledInternalLink } from "./InternalLink"
import Spacer from "./Spacer"

const StyledBox = styled(animated(Link))`
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  display: block;
  padding: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
  span.underline {
    height: 4px;
    width: 40px;
    background-color: #f03a85;
    display: block;
  }
  p {
    color: #66768e !important;
  }
  ${StyledInternalLink} {
    margin-top: auto;
  }
`

export default function ServiceBox({
  serviceDetails: { slug, title, summary },
}) {
  const [isHovered, setIsHovered] = useState(false)

  const boxAnimation = useSpring({
    boxShadow: isHovered
      ? "0px 24px 32px -16px rgb(64 38 105 / 24%)"
      : "0px 0px 0px 0px rgb(64 38 105 / 24%)",
    transform: isHovered ? "scale(1.03)" : "scale(1)",
    config: {
      tension: 300,
      friction: 15,
      clamp: true,
    },
  })
  return (
    <StyledBox
      to={`/services/${slug}/`}
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
      style={boxAnimation}
    >
      <h2>{title}</h2>
      <Spacer size={16} />
      <span className="underline" />
      <Spacer size={24} />
      <p>{summary}</p>
      <Spacer size={24} />
      <InternalLink as="div">Find Out More</InternalLink>
    </StyledBox>
  )
}
