import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Seo from "../components/Seo"
import BlogPreview from "../components/BlogPreview"
import PageHeader from "../components/PageHeader"
import Spacer from "../components/Spacer"
import FlexibleContent from "../components/FlexibleContent"
import ServiceBox from "../components/ServiceBox"

export default function About() {
  const {
    wpPage: {
      seo,
      featuredImage,
      pageHeaderFields: { headerContent },
      flexibleGeneralContentFields: { content: pageContent },
    },
    allWpService: { nodes: services },
  } = useStaticQuery(graphql`
    {
      wpPage(title: { eq: "Services" }) {
        pageHeaderFields {
          headerContent
        }
        seo {
          metaDesc
          title
          opengraphImage {
            localFile {
              publicURL
            }
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        flexibleGeneralContentFields {
          ...FlexibleContent
        }
      }
      allWpService {
        nodes {
          slug
          title
          servicesFields {
            summary
          }
        }
      }
    }
  `)

  return (
    <>
      <Seo
        seo={{
          ...seo,
          featuredImage: featuredImage?.node?.localFile?.publicURL,
        }}
      />
      <PageHeader headerContent={headerContent} />
      <Spacer size={32} when={{ lg: 48 }} />
      <div className="container">
        <div className="row">
          {services.map(({ slug, title, servicesFields: { summary } }) => (
            <div className="col-12 col-md-6" key={slug}>
              <div>
                <ServiceBox serviceDetails={{ slug, title, summary }} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Spacer size={32} when={{ md: 48 }} />
      <FlexibleContent content={pageContent} />
      <Spacer size={32} when={{ md: 48, lg: 80 }} />
      <BlogPreview />
    </>
  )
}
